import React from 'react';
import MealItem from '../small/MealItem';
import RecipeIndex from '../recipe/RecipeIndex';
import { useState } from 'react';
import { useEffect } from 'react';
import '../meal/Meal.css';

const Meal = () => {
  const [url,  setUrl] = useState("https:/www.themealdb.com/api/json/v1/1/search.php?f=a");
  const [item, setItem] = useState();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState();


  useEffect(() => {
    fetch(url)
    .then(res=>res.json())
    .then(data =>{
      console.log(data.meals);
      setItem(data.meals);
      setShow(true);
    })

  }, [url]);

  


  const setIndex = (alpha) => {
    setUrl(`https:/www.themealdb.com/api/json/v1/1/search.php?f=${alpha}`);
  }

  const searchRecipe = (evt) => {
    if(evt.key == "Enter") {
      setUrl(`https:/www.themealdb.com/api/json/v1/1/search.php?s=${search}`)
    }
  }

 




  return (
    <div className='main'>
        <div className='heading'>
            {/* <h1>Search Your Food Favourite Recipe</h1> */}
            <h1>Ginger's Recipe App </h1>
            {/* <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae similique vitae consectetur nihil, molestiae fugiat ullam voluptatibus
                 doloribus veritatis ipsam tempore ducimus cumque delectus maiores aliquid reprehenderit molestias minima dicta?</h4> */}
        </div>
        <div className='searchBox'>
            <input type="search" placeholder='Search your favourite recipe' className='search-bar' onChange={(e) => setSearch(e.target.value)} onKeyPress={searchRecipe} />
        </div>
        <div className='container'>
          {
            show ? <MealItem data={item} /> : <h1>RECIPES NOT AVAILABLE</h1>
          }
        </div>
        <div className="indexContainer">
          <RecipeIndex alphaIndex={(alpha) => setIndex(alpha)} />
        </div>
    </div>
  )
}

export default Meal;

